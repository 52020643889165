import React, { useEffect } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import PaymentTableComponent from "../../../components/admin/payment-manager/PaymentTable";
import { useGetPaymentHistoryListQuery, useUpdatePaymentStatusMutation } from "../../../store/common/commonApis";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { defaultPaginate } from "../../../helpers/constants";

const MembersManagerContainer = (props) => {
    const dispatch = useDispatch();
    const { pagination } = props;
    const { isLoading, data: paymentHistory, refetch, isFetching } = useGetPaymentHistoryListQuery(pagination);
    const [updatePaymentStatus, { isLoading: updateLoading }] = useUpdatePaymentStatusMutation();

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, [])

    const handleStatusUpdate = async (id, type) => {
        try {
            let payload = {
                _id: id,
                status: type
            }
            if (window.confirm(`Are you sure you want to ${type ? "Approve" : "Reject"} this payment request.`)) {
                await updatePaymentStatus(payload).unwrap();
                refetch();
                dispatch(setSnackBar({
                    open: true,
                    message: "Payment status updated successfully",
                    severity: "success",
                }))
            }
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    }

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={6} sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Payment Request</Typography>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <PaymentTableComponent update={(val, type) => handleStatusUpdate(val, type)} loading={isLoading || updateLoading} fetching={isFetching} count={paymentHistory?.count || 0} data={paymentHistory?.result || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                    </Paper>
                </Grid>
            </Grid>
        </Stack >
    );
}

export default MembersManagerContainer;