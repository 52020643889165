import React, { Fragment, useState } from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { Box, Button, Chip, TableCell, TableRow, Typography } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import TableCommon from "../../Common/Table";
import { baseUrl } from "../../../helpers/constants";
import { handleDateTimeDefault, JsonDecode } from "../../../helpers/utils";
import CommonModal from "../../Common/CommonModal";
import { boolean } from "yup";

const PaymentTableComponent = ({ loading, fetching = false, count, data, update, pagination, handlePagination }) => {
    console.log(data, "data");

    const [showPreview, setShowPreview] = useState(false);
    let columns = [
        {
            title: "Sr. No.",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Member Details",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Booking Details",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Amount Paid",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Payment Details",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Payment Date",
            field: "createdAt",
            sort: true,
            minWidth: 170,
        },
        {
            title: "Payment Status",
            field: "payment_status",
            sort: true,
            minWidth: 90,
        },
    ];

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            const paymentRes = JsonDecode(row?.payment_response);
            console.log(row, "row");
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{(index + 1) + (pagination.pageNo * pagination.limit)}</TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">Member Id: {row?.member_data?.member_id}</Typography>
                        <Typography variant="subtitle2">{row?.member_data?.name}</Typography>
                        <Typography variant="subtitle2">{row?.member_data?.email}</Typography>
                        <Typography variant="subtitle2">{row?.member_data?.mobile}</Typography>
                        <Typography variant="subtitle2">Gender: {row?.member_data?.gender}</Typography>
                    </TableCell>
                    <TableCell align="center">
                        {
                            row?.bookings_data && row?.bookings_data.map(function (data, key) {
                                return (
                                    <Fragment>
                                        <Typography variant="subtitle2"><strong>Booking Id:</strong> {data?.booking_id}</Typography>
                                        <Typography variant="subtitle2" key={key}><strong>Activity Name:</strong> {row?.activity_data[key]?.name} - {row?.activity_data[key]?.activity_id}</Typography>
                                    </Fragment>
                                )

                            })
                        }
                        {
                            row?.plans_data && row?.plans_data.map(function (data, key) {
                                return (
                                    <Fragment>
                                        <Typography variant="subtitle2" key={key}><strong>Plan Name:</strong> {data?.plan_name}</Typography>
                                        <Typography variant="subtitle2" key={key}><strong>Plan Type:</strong> {data?.plan_type}</Typography>
                                    </Fragment>
                                )
                            })
                        }
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">{row?.amount_paid} Rs.</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">Payment Id: {paymentRes?.tracking_id}</Typography>
                        <Typography variant="subtitle2">Payment Mode: {row?.payment_mode}</Typography>
                    </TableCell>
                    <TableCell align="center">{handleDateTimeDefault(row?.createdAt)}</TableCell>
                    <TableCell align="center">{row?.payment_status === "Success" ? <Chip label="Success" color="success" /> : row?.payment_status === "Failed" ? <Chip label="Failed" color="error" /> : <Chip label="Pending" color="info" />}</TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={7} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} />
    )
}

export default PaymentTableComponent;