import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { eventsListParser } from './eventsParser';

const eventsApis = createApi({
    reducerPath: 'eventsApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("asmc_token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["Event"],
    endpoints: build => ({
        getEventList: build.query({
            query: (params) => ({
                url: "/events/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => eventsListParser(response),
            providesTags: ['Event'],
        }),
        addNewEvent: build.mutation({
            query: (payload) => ({
                url: "/events",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Event'],
        }),
        updateEvent: build.mutation({
            query: (payload) => ({
                url: "/events",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Event'],
        }),
        updateStatus: build.mutation({
            query: (payload) => ({
                url: "/events/status",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Event'],
        }),
        deleteEvent: build.mutation({
            query: (params) => ({
                url: "/events",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Event'],
        }),
    }),
});

export const { useGetEventListQuery, useAddNewEventMutation, useUpdateEventMutation, useUpdateStatusMutation, useDeleteEventMutation } = eventsApis;
export default eventsApis;