import React, { Fragment } from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { FormControlLabel, Switch, TableCell, TableRow, Typography } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import TableCommon from "../../Common/Table";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { useDeleteBookingMutation, useUpdateStatusMutation } from "../../../store/booking/bookingApis";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useUpdateEventMutation } from "../../../store/events/eventsApis";

const EventsTableComponent = ({ loading, fetching = false, count, data, edit, pagination, handlePagination }) => {
    const dispatch = useDispatch();
    const [handleDelete] = useDeleteBookingMutation();
    const [updateEvents] = useUpdateEventMutation();

    let columns = [
        {
            title: "Sr. No.",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Event Id",
            field: "event_id",
            sort: true,
            minWidth: 50,
        },
        {
            title: "Event Name",
            field: "event_name",
            sort: true,
            minWidth: 50,
        },
        {
            title: "Start Date & Time",
            field: "start_date",
            sort: true,
            minWidth: 170,
        },
        {
            title: "End Date & Time",
            field: "end_date",
            sort: true,
            minWidth: 170,
        },
        {
            title: "Status",
            field: "status",
            sort: true,
            minWidth: 90,
        },
        {
            title: "Action",
            name: "",
            sort: false,
            minWidth: 200,
        },
    ];

    const deleteManage = (_id, converted) => {
        if (window.confirm("Are you sure you want to delete?")) {
            handleDelete({ _id });
        }
    }

    const handleChangeStatus = async (value, row) => {
        try {
            let payload = {
                status: value,
                _id: row._id,
                event_name: row.event_name,
                description: row.description,
            }
            await updateEvents(payload).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: `Event ${value ? "active" : "in-active"} successfully`,
                severity: "success",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    }

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{(index + 1) + (pagination.pageNo * pagination.limit)}</TableCell>
                    <TableCell align="center">{row?.event_id}</TableCell>
                    <TableCell align="center">{row?.event_name} </TableCell>
                    <TableCell align="center">{handleDateTimeDefault(row?.start_date, "dd-MM-yyyy")} - {handleDateTimeDefault(row?.start_time, "hh:mm:ss a")}</TableCell>
                    <TableCell align="center">{handleDateTimeDefault(row?.end_date, "dd-MM-yyyy")} - {handleDateTimeDefault(row?.end_time, "hh:mm:ss a")}</TableCell>
                    <TableCell align="center">
                        <FormControlLabel
                            control={<Switch checked={row?.status || false} />}
                            label="Active"
                            onChange={(e) => handleChangeStatus(e.target.checked, row)}
                        />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <IconButtonIcons
                            title="Edit"
                            IconComponent={EditIcon}
                            color="warning"
                            onClick={() => edit(row, "Edit")}
                        />
                        <IconButtonIcons
                            title="View"
                            IconComponent={EyeIcon}
                            color="info"
                            onClick={() => edit(row, "View")}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={7} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} />
    )
}

export default EventsTableComponent;