import { Grid, TextField } from "@mui/material"
import { debounce } from "../../helpers/utils";

export const SearchRecords = ({ handlePagination, pagination }) => {

    const handleSearch = (val) => {
        let keywords = val.toLowerCase();
        handlePagination({
            pageNo: 0,
            limit: 10,
            keywords: keywords
        })
    }

    const processChange = debounce((e) => handleSearch(e));

    return (
        <Grid item textAlign={"right"}>
            <TextField
                size="small"
                id="outlined-search"
                label="Search here..."
                type="search"
                style={{ minWidth: 300 }}
                onChange={(e) => processChange(e.target.value)}
            />
        </Grid>
    )
}