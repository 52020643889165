import { Grid } from "@mui/material";
import React, { Fragment } from "react";
import { CardSummary } from "../../../components/Common/CardSummary";
import DashboardSkeleton from "../../../components/Common/DashboardSkeleton";
import { isAuth } from "../../../helpers/cookies";
import Breadcrumbs from "../../../components/layout/Breadcrumbs";

const DashboardContainer = () => {
    const userRoles = isAuth().roles;

    return (
        <Fragment>
            <Breadcrumbs item={[]} />
        </Fragment>
    )

    // return (
    //     <>
    //         {
    //             isLoading
    //                 ?
    //                 <DashboardSkeleton />
    //                 :
    //                 <Grid container spacing={3} pb={3}>
    //                     {
    //                         userRoles === "admin" && (
    //                             <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
    //                                 <CardSummary
    //                                     title="Total Number of Sanstha"
    //                                     value={data.sanstha}
    //                                 />
    //                             </Grid>
    //                         )
    //                     }
    //                     <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
    //                         <CardSummary
    //                             title="Total Number of Members"
    //                             value={data.member}
    //                         />
    //                     </Grid>
    //                     <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
    //                         <CardSummary
    //                             title="Total Number of Candidates"
    //                             value={data.candidate}
    //                         />
    //                     </Grid>
    //                 </Grid>
    //         }
    //     </>
    // );
}

export default DashboardContainer;