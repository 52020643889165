export const baseUrl = "https://api.asmcdae.in";
// export const baseUrl = "http://localhost:7055"

export const loginUrl = "/auth/admin-login";

export const drawerWidth = 60;
export const expandDrawerWidth = 260;

export const fileTypes = ["jpeg", "jpg", "png", "gif"];
export const videoTypes = ["mp4"];

export const defaultPaginate = {
    pageNo: 0,
    limit: 10,
    sortBy: -1,
    sortField: "createdAt",
    keywords: ""
}

export const daysArray = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" }
]