import React from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { Chip, TableCell, TableRow, Typography } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import TableCommon from "../../Common/Table";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { baseUrl } from "../../../helpers/constants";
import { useDeleteMembersMutation } from "../../../store/members/membersApis";
import { handleDateTimeDefault } from "../../../helpers/utils";
import NoteAddIcon from '@mui/icons-material/NoteAdd';

const MembersTableComponent = ({ loading, fetching = false, count, data, edit, convert, pagination, handlePagination, generateCard }) => {
    const [handleDelete] = useDeleteMembersMutation();
    let columns = [
        {
            title: "Sr. No.",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Photo",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Member Id",
            field: "member_id",
            sort: true,
            minWidth: 50,
        },
        {
            title: "Personal Details",
            sort: false,
            minWidth: 170,
        },
        {
            title: "CHSS",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Plan",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Created At",
            field: "createdAt",
            sort: true,
            minWidth: 90,
        },
        {
            title: "Action",
            name: "",
            sort: false,
            minWidth: 200,
        },
    ];

    const deleteManage = (_id, converted) => {
        if (window.confirm("Are you sure you want to delete?")) {
            if (converted) {
                alert("This member is converted to user, kindly delete the user first and then member.")
                return false;
            }
            handleDelete({ _id });
        }
    }

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{(index + 1) + (pagination.pageNo * pagination.limit)}</TableCell>
                    <TableCell align="center">
                        <img src={row.profile} alt="Profile" width={50} />
                    </TableCell>
                    <TableCell align="center">
                        {row.member_id}
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">{row.name}</Typography>
                        <Typography variant="subtitle2">{row.email}</Typography>
                        <Typography variant="subtitle2">{row.mobile}</Typography>
                        <Typography variant="subtitle2">Gender: {row.gender}</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">{row?.chss_number}</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">
                            {row?.current_plan?.plan_name}
                        </Typography>
                        <Chip color={row?.fees_paid ? "success" : "error"} label={row?.fees_paid ? "Paid" : "Not Paid"}>
                            {row?.fees_paid ? "Paid" : "Not Paid"}
                        </Chip>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle2">{handleDateTimeDefault(row?.createdAt)}</Typography>
                        <Typography variant="subtitle2">{handleDateTimeDefault(row?.convertedAt)}</Typography>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <IconButtonIcons
                            title="Edit"
                            IconComponent={EditIcon}
                            color="warning"
                            onClick={() => edit(row, "Edit")}
                        />
                        <IconButtonIcons
                            title="View"
                            IconComponent={EyeIcon}
                            color="info"
                            onClick={() => edit(row, "View")}
                        />
                        <IconButtonIcons
                            title="Delete"
                            IconComponent={DeleteIcon}
                            color="error"
                            onClick={() => deleteManage(row._id, row.converted)}
                        />
                        {
                            !row.converted
                                ?
                                <IconButtonIcons
                                    title="Convert to User"
                                    IconComponent={AutorenewIcon}
                                    color="success"
                                    onClick={() => convert(row._id)}
                                />
                                :
                                null
                        }
                        <IconButtonIcons
                            title="Generate Card"
                            IconComponent={NoteAddIcon}
                            color="success"
                            onClick={() => generateCard(row)}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={7} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} />
    )
}

export default MembersTableComponent;