const LogoSection = ({ theme }) => {
    return (
        <img
            style={{
                width: "100%",
            }}
            src={"assets/images/logo-name.png"}
            alt='Logo'
        />
    )
}

export default LogoSection;