import { FormControl, FormLabel, RadioGroup } from "@mui/material"
import { Fragment } from "react"
import { handleDateTimeDefault } from "../../../helpers/utils"

export const CardContent = ({ cardData }) => {
    console.log(cardData, "cardData");
    return (
        <Fragment>
            <div className="main">
                <div className="card">
                    <div className="header">
                        <img src="/assets/images/logo-icon.png" alt="logo" width="70" />
                        <h1>ANUSHAKTINAGAR SPORTS MANAGEMENT COMMITTEE (ASMC)</h1>
                    </div>
                    <div className="profile">
                        <h2>Member Profile</h2>
                        <div className="profile-details">
                            <table>
                                <tr>
                                    <td style={{ minWidth: "300px" }}>Name</td>
                                    <td >{cardData?.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ minWidth: "300px" }}>Mobile No.</td>
                                    <td >{cardData?.mobile}</td>
                                </tr>
                                <tr>
                                    <td style={{ minWidth: "300px" }}>Email ID</td>
                                    <td >{cardData?.email}</td>
                                </tr>
                                <tr>
                                    <td style={{ minWidth: "300px" }}>CHSS / ID</td>
                                    <td >{cardData?.chss_number}</td>
                                </tr>
                                <tr>
                                    <td style={{ minWidth: "300px" }}>DOB</td>
                                    <td >{cardData?.dob ? handleDateTimeDefault(cardData?.dob, "dd/MM/yyyy") : "-"}</td>
                                </tr>
                            </table>
                            <div className="profile-photo">
                                <div className="photo"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="membership-details">
                    <h2>Membership Details</h2>
                    <div className="membership-table">
                        <table>
                            <tr>
                                <td>Membership No.</td>
                                <td style={{ minWidth: "800px" }}>{cardData?.membership_no}</td>
                            </tr>
                            <tr>
                                <td>Membership Type</td>
                                <td style={{ minWidth: "800px" }}>
                                    {cardData?.membership_type}
                                    {" "}
                                    <span style={{ color: "red", fontWeight: "bold" }}>{cardData?.chss_number === "NON CHSS" ? "(Non Dependent)" : null}</span>
                                    {cardData?.membership_type === "Secondary" && <span style={{ color: "red", fontWeight: "bold" }}>{cardData?.is_dependent ? null : "(Non Dependent)"}</span>}
                                </td>
                            </tr>
                            <tr>
                                <td>Membership Plan</td>
                                <td style={{ minWidth: "800px" }}>{cardData?.membership_plan}</td>
                            </tr>
                            <tr>
                                <td>Relation With Primary</td>
                                <td style={{ minWidth: "800px" }}>{cardData?.relation_with_primary}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="footer">
                    Office: NCC Complex, Opposite Post Office, Anushaktinagar<br />
                    Email: asmc.dae@gmail.com; Phone: 2558 0497
                </div>
            </div>
            <div className="back-main">
                <div class="table-container">
                    <table>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Sports Activities</th>
                            <th>Category Main / Sub</th>
                            <th>Valid Upto</th>
                            <th>Batch Name</th>
                            <th>Signature</th>
                        </tr>
                        {
                            cardData?.bookings && cardData?.bookings.map((obj, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <span>{obj?.activity}</span>
                                            <span style={{ display: "block", fontSize: 35 }}>{obj?.location ? `${obj?.location}` : ""} {obj?.sublocation ? `(${obj?.sublocation})` : ""}</span>
                                        </td>
                                        <td>
                                            <span>{obj?.category}</span>
                                            <span style={{ display: "block", fontSize: 35 }}>{obj?.sub_category ? `(${obj?.sub_category})` : ""}</span>
                                        </td>
                                        <td>{obj?.valid_upto}</td>
                                        <td>{obj?.batch_name}</td>
                                        <td></td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>
            </div>
        </Fragment>
    )
}