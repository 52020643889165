import React from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { FormControlLabel, Switch, TableCell, TableRow, Typography } from "@mui/material";
import IconButtonIcons from "../../Common/IconButtonIcons";
import TableCommon from "../../Common/Table";
import { baseUrl } from "../../../helpers/constants";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { useDeleteBatchMutation, useUpdateBatchMutation } from "../../../store/masters/mastersApis";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../store/common/commonSlice";

const BatchTableComponent = ({ loading, fetching = false, count, data, edit, pagination, handlePagination }) => {
    const dispatch = useDispatch();
    const [handleDelete] = useDeleteBatchMutation();
    const [updateBatch] = useUpdateBatchMutation();
    let columns = [
        {
            title: "Sr. No.",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Batch Details",
            sort: false,
            minWidth: 90,
        },
        {
            title: "Type",
            field: "type",
            sort: true,
            minWidth: 50,
        },
        {
            title: "Sports Activity",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Categories",
            sort: false,
            minWidth: 50,
        },
        {
            title: "Locations",
            sort: false,
            minWidth: 170,
        },
        {
            title: "Active",
            field: "status",
            sort: true,
            minWidth: 90,
        },
        {
            title: "Action",
            name: "",
            sort: false,
            minWidth: 200,
        },
    ];

    const deleteManage = (_id, converted) => {
        if (window.confirm("Are you sure you want to delete?")) {
            if (converted) {
                alert("This member is converted to user, kindly delete the user first and then member.")
                return false;
            }
            handleDelete({ _id });
        }
    }

    const handleChangeStatus = async (value, row) => {
        try {
            let payload = {
                _id: row?._id,
                batch_code: row?.batch_code,
                batch_name: row?.batch_name,
                batch_type: row?.batch_type,
                batch_limit: row?.batch_limit,
                days: row?.days,
                start_time: row?.start_time,
                end_time: row?.end_time,
                sublocation_id: row?.sublocation_id,
                category_id: row?.category_id,
                location_id: row?.location_id,
                court: row?.court || "",
                activity_id: row?.activity_id,
                type: row?.type,
                status: value
            }
            await updateBatch(payload).unwrap();
            dispatch(setSnackBar({
                open: true,
                message: `Batch ${value ? "active" : "in-active"} successfully`,
                severity: "success",
            }))
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    }

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" } }}
                >
                    <TableCell align="center">{(index + 1) + (pagination.pageNo * pagination.limit)}</TableCell>
                    <TableCell align="center">
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }} color="text.secondary" >Batch Type: {row?.batch_type}</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }} color="text.secondary" >Batch Code: {row?.batch_code}</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }} color="text.secondary" >Batch Name: {row?.batch_name}</Typography>
                    </TableCell>
                    <TableCell align="center">
                        {row?.type.toUpperCase()}
                    </TableCell>
                    <TableCell align="center">
                        {row?.activity_data?.name}
                    </TableCell>
                    <TableCell align="center">
                        {row?.category_data?.title}
                    </TableCell>
                    <TableCell align="center">
                        {row?.location_data?.title}
                    </TableCell>
                    <TableCell align="center">
                        <FormControlLabel
                            control={<Switch checked={row?.status || false} />}
                            label="Active"
                            onChange={(e) => handleChangeStatus(e.target.checked, row)}
                        />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                        <IconButtonIcons
                            title="Edit"
                            IconComponent={EditIcon}
                            color="warning"
                            onClick={() => edit(row, "Edit")}
                        />
                        <IconButtonIcons
                            title="View"
                            IconComponent={EyeIcon}
                            color="info"
                            onClick={() => edit(row, "View")}
                        />
                        <IconButtonIcons
                            title="Delete"
                            IconComponent={DeleteIcon}
                            color="error"
                            onClick={() => deleteManage(row._id, row.converted)}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={8} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} />
    )
}

export default BatchTableComponent;