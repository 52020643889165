import React, { useEffect } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import UserTableComponent from "../../../components/admin/user-manager/UserTable";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import { useGetMembersListQuery } from "../../../store/members/membersApis";
import { defaultPaginate } from "../../../helpers/constants";

const UserManagerContainer = (props) => {
    const { pagination } = props;

    const { isLoading, data: users, isFetching } = useGetMembersListQuery({ ...pagination, converted: "true" });

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, [])

    return (
        <Stack spacing={1}>
            <Paper sx={{ marginBottom: "24px", padding: 1.5 }}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ alignSelf: "center" }}>
                        <Typography variant="h6">List of Users</Typography>
                    </Grid>
                    <Grid item sx={{ alignSelf: "center" }}>
                        <SearchRecords handlePagination={handlePagination} pagination={pagination} />
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <UserTableComponent
                            fetching={isFetching}
                            loading={isLoading}
                            count={users?.count || 0} data={users?.result || []}
                            pagination={pagination}
                            handlePagination={(val) => handlePagination(val)}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Stack >
    );
}

export default UserManagerContainer;