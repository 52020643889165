import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";
import { paymentHistoryParser } from './commonParser';

const commonApis = createApi({
    reducerPath: 'commonApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("asmc_token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["PaymentHistory"],
    endpoints: build => ({
        getDashboardCount: build.query({
            query: (params) => ({
                url: "/common/dashboard-count",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.result,
        }),
        handleImageUpload: build.mutation({
            query: (payload) => ({
                url: "/common/upload-single-image",
                method: "POST",
                body: payload,
                formData: true
            }),
            transformResponse: (response) => response.result,
        }),
        handleMultipleImageUpload: build.mutation({
            query: (payload) => ({
                url: "/common/upload-multiple-image",
                method: "POST",
                body: payload,
                formData: true
            }),
            transformResponse: (response) => response.result,
        }),
        getPaymentHistoryList: build.query({
            query: (params) => ({
                url: "/payment/payment-list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => paymentHistoryParser(response),
            providesTags: ['PaymentHistory'],
            keepUnusedDataFor: 5,
        }),
        updatePaymentStatus: build.mutation({
            query: (payload) => ({
                url: "/common/payment-status",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['PaymentHistory'],
        }),
        getContactLeadsList: build.query({
            query: (params) => ({
                url: "/common/contact-us",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.result,
        }),
    }),
});

export const { useGetDashboardCountQuery, useHandleImageUploadMutation, useHandleMultipleImageUploadMutation, useGetPaymentHistoryListQuery, useUpdatePaymentStatusMutation, useGetContactLeadsListQuery } = commonApis;
export default commonApis;